import React, { useRef, useState } from "react";
import func from "./functionAdd";
import json from '../json.json';
import Boxes from "./boxes";

export default function Contact() {
    const nameRef= useRef();
    const messageRef = useRef();
    const titleRef = useRef();
    const sendtoRef = useRef();
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    const handleChange = (event) => {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };
    
    const [language, setLanguage] = useState();
    const handleLocalStorage = () => {
        let es = localStorage.getItem("language");
        if(es === "true"){
          setLanguage(false);
        }else{
          setLanguage(true);
        }
      };
      setTimeout(handleLocalStorage, 1);

      const [onModal1, setOnModal1] = useState(false);
      const [onTypeModal1, setOnTypeModal1] = useState("");
      let modalSend = onModal1 ? (<Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />) :("");
  
      const modalEmail = () => {  setOnTypeModal1("modal"); setOnModal1(true); }; 
    const sendMailt = async () => 
    {

        var param = {name:nameRef.current.value, message:messageRef.current.value, sendto:sendtoRef.current.value, title:titleRef.current.value}
        console.log(param);
        await func.EjectFetch('apimailer/index.php',param);
        modalEmail()

    }

    return (
        <>
        {modalSend}
            <section class="contenido">
                <article class="info-img">
                    <div class="informacion-header">
                        <h1 class="header-titulo">{ language ? json.En.headerTitleContact : json.Es.headerTitleContact }</h1>
                        <h3 class="subtitulo-header">{ language ? json.En.headerSubtitleContact : json.Es.headerSubtitleContact }</h3>
                        <p class="texto-header">{ language ? json.En.headerTextContact : json.Es.headerTextContact }</p>
                    </div>
                    <div class="imagen-h">
                        <img src="css/images/img-header-contacto_.png" class="image-head" style={{ "width": "70%" }}alt="walliacontacto" />
                    </div>
                </article>
                <section class="contacto-datos">
                    <article class="cajas-contacto">
                        <article class="box-contact-datos">
                            <p class="subtitle-contact">{ language ? json.En.articleSubtitleContact : json.Es.articleSubtitleContact }</p>
                            <p class="text-contact-datos">{ language ? json.En.articleTextContact : json.Es.articleTextContact }</p>
                            <p class="datos">
                                <img src="css/images/foot-phone_.png" class="icon-datos" alt="walliaphone"/>5540 5234 64</p>
                            <p class="datos">
                                <img src="css/images/foot-mail_.png" class="icon-datos" alt="walliamail"/>info@wallia.io</p>
                            <p class="datos">
                                <img src="css/images/icon-pin-wallia-c.png" class="icon-datos" alt="walliapin"/>MÉXICO - LATAM - USA</p>
                            <img src="css/images/mapa-gris_.png" class="map" alt=""/>
                        </article>
                        <article class="box-contact-datos">
                            <article class="form-fondo">
                                <form  class="form-contact-datos">
                                    <div class="campo-datos">
                                        <label for="fname" class="label-contact-datos">{ language ? json.En.name : json.Es.name }</label><br />
                                        <input 
                                            type="text"
                                            id="fname" 
                                            name="name"
                                            class="campo-contact-datos"
                                            ref={nameRef}
                                            onChange={handleChange} />
                                    </div>
                                    <div class="campo-datos">
                                        <label for="email" class="label-contact-datos">{ language ? json.En.email : json.Es.email }</label><br />
                                        <input 
                                            type="email" 
                                            id="email" 
                                            name="sendto" 
                                            class="campo-contact-datos"
                                            ref={sendtoRef}
                                            onChange={handleChange} />
                                    </div>
                                    <div class="campo-datos">
                                        <label for="digital" class="label-contact-datos">{ language ? json.En.title : json.Es.title }</label><br />
                                        <select id="contact-datos"
                                            name="title"
                                            onChange={handleChange}
                                            ref={titleRef}>
                                            <option value="app">{ language ? json.En.appMobile : json.Es.appMobile }</option>
                                            <option value="web">{ language ? json.En.web : json.Es.web }</option>
                                            <option value="solution">{ language ? json.En.solition : json.Es.solition }</option>
                                            <option value="adata">{ language ? json.En.data : json.Es.data }</option>
                                        </select>
                                    </div>
                                    <div class="campo-datos">
                                        <label for="message" class="label-contact-datos">{ language ? json.En.message : json.Es.message }</label><br />
                                        <textarea
                                             name="message" 
                                             rows="10" 
                                             cols="30" 
                                             class="campo-contact-datos-mensaje"
                                             ref={messageRef}
                                            onChange={handleChange}>
                                        </textarea>
                                    </div>
                                    <div class="campo-datos">
                                        <input value={language ? json.En.send : json.Es.send } onClick={sendMailt} class="enviar-datos" style={{cursor: "pointer"}}/>
                                    </div>
                                </form>
                            </article>
                        </article>
                    </article>
                    <section class="contenido-clientes">
                        <h2>{language ? json.En.h2Clients1 : json.Es.h2Clients1 }</h2>
                        <article class="clientes">
                            <img src="css/images/cliente01_.png" class="cliente-logo" alt="walliasaba"/>
                            <img src="css/images/cliente10.png" class="cliente-logo" alt="walliataf"/>
                            <img src="css/images/cliente03_.png" class="cliente-logo" alt="walliapetco"/>
                            <img src="css/images/cliente09.png" class="cliente-logo" alt="walliaguess"/>
                            <img src="css/images/cliente05_.png" class="cliente-logo" alt="walliashake"/>
                            <img src="css/images/cliente06_.png" class="cliente-logo" alt="walliacalvinklein"/>
                            <img src="css/images/cliente07_.png" class="cliente-logo" alt="walliatommy"/>
                            <img src="css/images/cliente08_.png" class="cliente-logo" alt="walliabath"/>
                        </article>
                    </section>
                </section>
            </section>
        </>
    );
}


