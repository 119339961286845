import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import json from '../json.json';

export default function Home() {
    const history = useHistory();
    const goContact = () => { history.push("/contact")};
    const goMobile = () => { history.push("/mobile-service") };
    const goDigitalCons = () => { history.push ("/service-consulting")};
    const goServiceBi = () => { history.push("/service-bi")};
    const goWebServ = () => { history.push("/web-service") };
    const [language, setLanguage] = useState();
    const handleLocalStorage = () => {
        let es = localStorage.getItem("language");
        if(es === "true"){
          setLanguage(false);
        }else{
          setLanguage(true);
        }
      };
      setTimeout(handleLocalStorage, 1);

    return (
        <>
            <section class="contenido">
                <section class="banner-wallia">
                    <article class="caja-banner">
                        <h1>{language ? json.En.h1Home : json.Es.h1Home }<br/>
                        {language ? json.En.de : json.Es.de } <span class="azul">{language ? json.En.spamHome : json.Es.spamHome }</span></h1>
                        <h3>{language ? json.En.h3Home : json.Es.h3Home }.</h3>
                        <div class="btn-contactanos" onClick={goContact} style={{cursor:"pointer"}}>{language ? json.En.contact : json.Es.contact }</div>
                    </article>
                </section>
                <section class="contenido-icon-home">
                    <article class="cajas-icon">
                        <article class="icon-box">
                            <img src="css/images/icon-h-simplificar_.png" class="icon-home" alt="walliasimplificar"/>
                            <p class="icon-text">{language ? json.En.textHome : json.Es.textHome }</p>
                        </article>
                        <article class="icon-box">
                            <img src="css/images/icon-h-innovar_.png" class="icon-home" alt="walliainovar"/>
                            <p class="icon-text">{language ? json.En.textHome2 : json.Es.textHome2 }</p>
                        </article>
                        <article class="icon-box">
                            <img src="css/images/icon-h-digitalizar_.png" class="icon-home" alt="walliadigitalizar"/>
                            <p class="icon-text">{language ? json.En.textHome3 : json.Es.textHome3 }</p>
                        </article>
                    </article>
                </section>
                <section class="contenido-transform">
                    <article class="cajas-transform">
                        <article class="transform-box1">
                            <div class="caja-interior-transform">
                                <p class="title-transform">{language ? json.En.titleHome : json.Es.titleHome }</p>
                                <p class="text-transform">{language ? json.En.textTransformHome : json.Es.textTransformHome }</p>
                                <div class="btn-informacion" onClick={goContact} style={{cursor:"pointer"}}>{language ? json.En.informationHome : json.Es.informationHome }</div>
                            </div>
                        </article>
                        <article class="transform-box2">
                            <img src="css/images/screens-home_.png" id="image-trans" alt="walliaexpertosdigital"/>
                        </article>
                    </article>
                </section>
                <section class="contenido-clientes">
                    <h2>{language ? json.En.h2Clients1 : json.Es.h2Clients1 }</h2>
                    <article class="clientes">
                    <img src="css/images/cliente01_.png" class="cliente-logo" alt="walliasaba"/>
                            <img src="css/images/cliente10.png" class="cliente-logo" alt="walliataf"/>
                            <img src="css/images/cliente03_.png" class="cliente-logo" alt="walliapetco"/>
                            <img src="css/images/cliente09.png" class="cliente-logo" alt="walliaguess"/>
                            <img src="css/images/cliente05_.png" class="cliente-logo" alt="walliashake"/>
                            <img src="css/images/cliente06_.png" class="cliente-logo" alt="walliacalvinklein"/>
                            <img src="css/images/cliente07_.png" class="cliente-logo" alt="walliatommy"/>
                            <img src="css/images/cliente08_.png" class="cliente-logo" alt="walliabath"/>
                    </article>
                </section>
                <section class="contenido-proceso">
                    <p class="text-do">{language ? json.En.textDoHome : json.Es.textDoHome }</p>
                    <h2>{language ? json.En.h2Home : json.Es.h2Home }</h2>
                    <div class="caja-proceso">
                        <div class="paso">
                            <div class="paso-numero">
                                <img src="css/images/num05_.png" class="img-num" alt="walliauno"/></div>
                            <div class="paso-descripcion">
                                <img src="css/images/icons-home-process.png" class="img-paso" alt="procesos"/>
                                <p class="paso-title">{language ? json.En.pasoTitleHome : json.Es.pasoTitleHome }</p>
                                <p class="paso-texto">{language ? json.En.pasoTextHome : json.Es.pasoTextHome }</p>
                            </div>
                        </div>
                        <div class="paso">
                            <div class="paso-numero">
                                <img src="css/images/num06_.png" class="img-num" alt="walliados"/></div>
                            <div class="paso-descripcion">
                                <img src="css/images/icons-home-tiempo.png" class="img-paso" alt="mejoratiempos"/>
                                <p class="paso-title">{language ? json.En.pasoTitleHome2 : json.Es.pasoTitleHome2 }</p>
                                <p class="paso-texto">{language ? json.En.pasoTextHome2 : json.Es.pasoTextHome2 }</p>
                            </div>
                        </div>
                    </div>
                    <div class="caja-proceso">
                        <div class="paso">
                            <div class="paso-numero">
                                <img src="css/images/num07_.png" class="img-num" alt="walliatres"/></div>
                            <div class="paso-descripcion">
                                <img src="css/images/proceso-03_.png" class="img-paso" alt="controldata"/>
                                <p class="paso-title">{language ? json.En.pasoTitleHome3 : json.Es.pasoTitleHome3 }</p>
                                <p class="paso-texto">{language ? json.En.pasoTextHome3 : json.Es.pasoTextHome3 }</p>
                            </div>
                        </div>
                        <div class="paso">
                            <div class="paso-numero">
                                <img src="css/images/num08_.png" class="img-num" alt="walliacuatro"/></div>
                            <div class="paso-descripcion">
                                <img src="css/images/proceso-04_.png" class="img-paso" alt="experencialcliente"/>
                                <p class="paso-title">{language ? json.En.pasoTitleHome4 : json.Es.pasoTitleHome4 }</p>
                                <p class="paso-texto">{language ? json.En.pasoTextHome4 : json.Es.pasoTextHome4 }</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="contenido-portfolio">
                    <div class="caja-portafolio">
                        <div class="caja-portafolio-descrip">
                            <p class="text-contamos">{language ? json.En.textContamosHome : json.Es.textContamosHome }</p>
                            <h2 class="titulo-portafolio">{language ? json.En.titlePortfolioHome : json.Es.titlePortfolioHome }</h2>
                            <div class="servicios-portafolio">
                                <a onClick={goMobile} style={{textDecoration: "none", cursor: "pointer"}}><div class="portafolio-servicio">
                                    <img src="css/images/check-azul_.png" class="paloma-azul"   alt="walliapalomazul"/>
                                    <p class="texto-portafolio">{language ? json.En.titlePortfolioHome2 : json.Es.titlePortfolioHome2 }</p>
                                    <img src="css/images/flecha-azul_.png" class="flecha-azul" alt="walliaflechaazul"/>
                                </div></a>
                                <a onClick={goServiceBi} style={{textDecoration: "none", cursor: "pointer"}}>
                                    <div class="portafolio-servicio">
                                        <img src="css/images/check-azul_.png" class="paloma-azul"  alt="walliapalomazul"/>
                                        <p class="texto-portafolio" >{language ? json.En.titlePortfolioHome3 : json.Es.titlePortfolioHome3 }</p>
                                        <img src="css/images/flecha-azul_.png" class="flecha-azul"alt="walliaflechaazul" />
                                    </div></a>
                                <a onClick={goWebServ} style={{textDecoration: "none", cursor: "pointer"}} >
                                    <div class="portafolio-servicio">
                                        <img src="css/images/check-azul_.png" class="paloma-azul"  alt="walliapalomazul"/>
                                        <p class="texto-portafolio" >{language ? json.En.titlePortfolioHome4 : json.Es.titlePortfolioHome4 }</p>
                                        <img src="css/images/flecha-azul_.png" class="flecha-azul" alt="walliaflechaazul"/>
                                    </div>
                                </a>
                                <a onClick={goDigitalCons} style={{textDecoration: "none", cursor: "pointer"}} >
                                    <div class="portafolio-servicio">
                                        <img src="css/images/check-azul_.png" class="paloma-azul" alt="walliapalomazul"/>
                                        <p class="texto-portafolio" >{language ? json.En.titlePortfolioHome5 : json.Es.titlePortfolioHome5 }</p>
                                        <img src="css/images/flecha-azul_.png" class="flecha-azul" alt="walliaflechaazul"/>
                                    </div></a>
                            </div>
                        </div>
                        <div class="caja-portafolio-image">
                            <img src="css/images/servicios-home_.png" class="servcios-image" alt="walliaservicios"/>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}