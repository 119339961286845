const { default: axios } = require("axios");

const REACT_APP_WALLIA_API='https://wallia.dev/';

const EjectFetch = async(endpoint,param) =>  
{ 
   let jsonres = await axios.post(REACT_APP_WALLIA_API+endpoint, JSON.stringify({
        name:param.name+","+param.sendto+","+param.title+","+param.message
}), {
    "headers": {
      "Accept": 'application / json',
      'Content-Type': 'application / json'
    }
    }).then(async (response) => {
        console.log ("response axios success: "+ JSON.stringify(response.data));
      })
      .catch ((error) => {
        console.log ("response axios error:", error);
      }); 
    
    var jsonAuthResponse = await jsonres;
    return jsonAuthResponse;
 }
const func = {
  EjectFetch,
}
export default func;
// module.exports = {
//     EjectFetch,
// };