import { color } from "@mui/system";
import React, { useState,Alert } from "react";
import json from '../json.json';
export default function Boxes({type, setOnModal}) {
    let contModal = "";

    const [language, setLanguage] = useState();
    const handleLocalStorage = () => {
        let es = localStorage.getItem("language");
        if(es === "true"){
          setLanguage(false);
        }else{
          setLanguage(true);
        }
      };
      setTimeout(handleLocalStorage, 1);


    switch(type){
            case "petco":
            contModal = (
                <><article class="box-tab-marca">
                    <img src="css/images/image-petco_.png" class="image-petco" alt=""/>
                </article><article class="box-tab-marca1">
                        <p class="title-marca-gray">PETCO</p>
                        <p class="app">{ language ? json.En.appNative : json.Es.appNative }</p>
                        <p class="app-system">{ language ? json.En.SistemIOSANDROID : json.Es.SistemIOSANDROID }</p>
                        <p class="text-descripcion">{ language ? json.En.DescriptionMobile : json.Es.DescriptionMobile }.</p>
                        <div class="bullets-marca">
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>E-commerce</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>Easy Buy</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Management : json.Es.Management }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Loyalty : json.Es.Loyalty }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Location : json.Es.Location }</p>
                        </div>
                        <article class="detalle-cliente">
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion1_.png" class="icon-detalle" alt="walliaflujo"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile1 : json.Es.TextDetailMobile1 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion2_.png" class="icon-detalle" alt="walliaprecio" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile2 : json.Es.TextDetailMobile2 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion3_.png" class="icon-detalle" alt="walliabusqueda"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile3 : json.Es.TextDetailMobile3 }</p>
                            </div>
                        </article>
                    </article></>
            );
            break;
            case "shakeshack":
            contModal = (
                
                <><article class="box-tab-marca">
                    <img src="css/images/image-petco_.png" class="image-petco" alt=""/>
                </article><article class="box-tab-marca1">
                        <p class="title-marca-gray">PETCO</p>
                        <p class="app">{ language ? json.En.appNative : json.Es.appNative }</p>
                        <p class="app-system">{ language ? json.En.SistemIOSANDROID : json.Es.SistemIOSANDROID }</p>
                        <p class="text-descripcion">{ language ? json.En.DescriptionMobile : json.Es.DescriptionMobile }.</p>
                        <div class="bullets-marca">
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>E-commerce</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>Easy Buy</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Management : json.Es.Management }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Loyalty : json.Es.Loyalty }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Location : json.Es.Location }</p>
                        </div>
                        <article class="detalle-cliente">
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion1_.png" class="icon-detalle" alt="walliaflujo"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile1 : json.Es.TextDetailMobile1 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion2_.png" class="icon-detalle" alt="walliaprecio"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile2 : json.Es.TextDetailMobile2 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion3_.png" class="icon-detalle" alt="walliabusqueda"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile3 : json.Es.TextDetailMobile3 }</p>
                            </div>
                        </article>
                    </article></>
            );
            break;
            case "essity":
            contModal = (
                
                <><article class="box-tab-marca">
                    <img src="css/images/image-petco_.png" class="image-petco" alt=""/>
                </article><article class="box-tab-marca1">
                        <p class="title-marca-gray">PETCO</p>
                        <p class="app">{ language ? json.En.appNative : json.Es.appNative }</p>
                        <p class="app-system">{ language ? json.En.SistemIOSANDROID : json.Es.SistemIOSANDROID }</p>
                        <p class="text-descripcion">{ language ? json.En.DescriptionMobile : json.Es.DescriptionMobile }.</p>
                        <div class="bullets-marca">
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>E-commerce</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>Easy Buy</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Management : json.Es.Management }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Loyalty : json.Es.Loyalty }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Location : json.Es.Location }</p>
                        </div>
                        <article class="detalle-cliente">
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion1_.png" class="icon-detalle" alt="walliaflujo"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile1 : json.Es.TextDetailMobile1 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion2_.png" class="icon-detalle" alt="walliaprecio" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile2 : json.Es.TextDetailMobile2 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion3_.png" class="icon-detalle" alt="walliabusqueda"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile3 : json.Es.TextDetailMobile3 }</p>
                            </div>
                        </article>
                    </article></>
            );
            break;
            case "tommy":
            contModal = (
                
                <><article class="box-tab-marca">
                    <img src="css/images/image-petco_.png" class="image-petco" alt=""/>
                </article><article class="box-tab-marca1">
                        <p class="title-marca-gray">PETCO</p>
                        <p class="app">{ language ? json.En.appNative : json.Es.appNative }</p>
                        <p class="app-system">{ language ? json.En.SistemIOSANDROID : json.Es.SistemIOSANDROID }</p>
                        <p class="text-descripcion">{ language ? json.En.DescriptionMobile : json.Es.DescriptionMobile }.</p>
                        <div class="bullets-marca">
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>E-commerce</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>Easy Buy</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Management : json.Es.Management }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Loyalty : json.Es.Loyalty }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Location : json.Es.Location }</p>
                        </div>
                        <article class="detalle-cliente">
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion1_.png" class="icon-detalle" alt="walliaflujo" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile1 : json.Es.TextDetailMobile1 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion2_.png" class="icon-detalle" alt="walliaprecio"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile2 : json.Es.TextDetailMobile2 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion3_.png" class="icon-detalle" alt="walliabusqueda" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile3 : json.Es.TextDetailMobile3 }</p>
                            </div>
                        </article>
                    </article></>
            );
            break;
            case "calvin":
            contModal = (
                
                <><article class="box-tab-marca">
                    <img src="css/images/image-petco_.png" class="image-petco" alt=""/>
                </article><article class="box-tab-marca1">
                        <p class="title-marca-gray">PETCO</p>
                        <p class="app">{ language ? json.En.appNative : json.Es.appNative }</p>
                        <p class="app-system">{ language ? json.En.SistemIOSANDROID : json.Es.SistemIOSANDROID }</p>
                        <p class="text-descripcion">{ language ? json.En.DescriptionMobile : json.Es.DescriptionMobile }.</p>
                        <div class="bullets-marca">
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>E-commerce</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>Easy Buy</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Management : json.Es.Management }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Loyalty : json.Es.Loyalty }</p>
                            <p class="bullet-descrip"><img src="css/images/check-blue_.png" class="icon-check" alt="walliacheck"/>{ language ? json.En.Location : json.Es.Location }</p>
                        </div>
                        <article class="detalle-cliente">
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion1_.png" class="icon-detalle" alt="walliaflujo" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile1 : json.Es.TextDetailMobile1 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion2_.png" class="icon-detalle" alt="walliaprecio"/>
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile2 : json.Es.TextDetailMobile2 }</p>
                            </div>
                            <div class="box-detalle">
                                <img src="css/images/icon-descripcion3_.png" class="icon-detalle" alt="walliabusqueda" />
                                <p class="text-detalle">{ language ? json.En.TextDetailMobile3 : json.Es.TextDetailMobile3 }</p>
                            </div>
                        </article>
                    </article></>
            );
            break;
            case "app-mobile":
            contModal = (
                
                <article class="vacante-descrip app-mobile" >
                    {/* style={{ display: style.display }} */}
                    <p class="title-vacant-des">
                        <img src="css/images/vacantes.png" class="icon-vacant" alt="walliavacante" />{ language ? json.En.TextVacantMobile : json.Es.TextVacantMobile }</p>
                    <br />
                    <p class="texto-vacant-descrip">{ language ? json.En.TextVacantMobileDescription : json.Es.TextVacantMobileDescription }</p>

                    <p class="subtitle-vacante">{ language ? json.En.TextResponsabilitiesMobile : json.Es.TextResponsabilitiesMobile }</p>
                   

                    <p class="texto-vacant-descrip">
                    <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile1 : json.Es.TextResponsabilitiesMobile1 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile2 : json.Es.TextResponsabilitiesMobile2 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile3 : json.Es.TextResponsabilitiesMobile3 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile4 : json.Es.TextResponsabilitiesMobile4 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile5 : json.Es.TextResponsabilitiesMobile5 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile6 : json.Es.TextResponsabilitiesMobile6 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile7 : json.Es.TextResponsabilitiesMobile7 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile8 : json.Es.TextResponsabilitiesMobile8 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile9 : json.Es.TextResponsabilitiesMobile9 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile10 : json.Es.TextResponsabilitiesMobile10 }<br />
                    </p>
                    <p class="subtitle-vacante">{ language ? json.En.TextRqusitosesMobile : json.Es.TextRqusitosesMobile }</p>
                    <p class="texto-vacant-descrip" style={{ lineHeight: "1.8em" }}>
                        - { language ? json.En.textTeam1 : json.Es.textTeam1 }<br />

                        - { language ? json.En.textTeam2 : json.Es.textTeam2 }<br />

                        - { language ? json.En.textTeam3 : json.Es.textTeam3 }<br />

                        - { language ? json.En.textTeam4 : json.Es.textTeam4 }<br />

                        - { language ? json.En.textTeam5 : json.Es.textTeam5 }<br />

                        - { language ? json.En.textTeam6 : json.Es.textTeam6 }<br />

                        - { language ? json.En.textTeam7 : json.Es.textTeam7 }<br />
                    </p>
                </article>
            );
            break;
            case "dotnet":
            contModal = (
                <article class="vacante-descrip dotnet">
                    {/* style={{ display: style1.display }} */}
                    <p class="title-vacant-des">
                        <img src="css/images/vacantes.png" class="icon-vacant" alt="walliavacante"/>{ language ? json.En.TextVacantMobile : json.Es.TextVacantMobile }</p>
                    
                    <br />
                    <p class="texto-vacant-descrip">{ language ? json.En.TextVacantMobileDescription2 : json.Es.TextVacantMobileDescription2 }</p>

                    <p class="subtitle-vacante">{ language ? json.En.TextResponsabilitiesMobile : json.Es.TextResponsabilitiesMobile }</p>

                    <p class="texto-vacant-descrip">
                    <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile1 : json.Es.TextResponsabilitiesMobile1 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile2 : json.Es.TextResponsabilitiesMobile2 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile3 : json.Es.TextResponsabilitiesMobile3 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile4 : json.Es.TextResponsabilitiesMobile4 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile5 : json.Es.TextResponsabilitiesMobile5 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile6 : json.Es.TextResponsabilitiesMobile6 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile7 : json.Es.TextResponsabilitiesMobile7 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile8 : json.Es.TextResponsabilitiesMobile8 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile9 : json.Es.TextResponsabilitiesMobile9 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile10 : json.Es.TextResponsabilitiesMobile10 }<br />
                    </p>
                    <p class="subtitle-vacante">{ language ? json.En.TextRqusitosesMobile : json.Es.TextRqusitosesMobile }</p>
                    <p class="texto-vacant-descrip" style={{ lineHeight: "1.8em" }}>
                        - { language ? json.En.textTeam1 : json.Es.textTeam1 }<br />

                        - { language ? json.En.textTeam2 : json.Es.textTeam2 }<br />

                        - { language ? json.En.textTeam3 : json.Es.textTeam3 }<br />

                        - { language ? json.En.textTeam4 : json.Es.textTeam4 }<br />

                        - { language ? json.En.textTeam5 : json.Es.textTeam5 }<br />

                        - { language ? json.En.textTeam6 : json.Es.textTeam6 }<br />

                        - { language ? json.En.textTeam7 : json.Es.textTeam7 }<br />
                    </p>
                </article>
                
            );
            break;
            case "dev-bi":
            contModal = (
                <article class="vacante-descrip dev-bi" >
                    {/* style={{ display: style2.display }} */}
                    <p class="title-vacant-des">
                        <img src="css/images/vacantes.png" class="icon-vacant" alt="walliavacante"/>{ language ? json.En.TextVacantMobile : json.Es.TextVacantMobile }</p>
                    <br />
                    <p class="texto-vacant-descrip">{ language ? json.En.TextVacantMobileDescription3 : json.Es.TextVacantMobileDescription3 }</p>

                    <p class="subtitle-vacante">{ language ? json.En.TextResponsabilitiesMobile : json.Es.TextResponsabilitiesMobile }</p>

                  
                    <p class="texto-vacant-descrip">
                    <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile1 : json.Es.TextResponsabilitiesMobile1 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile2 : json.Es.TextResponsabilitiesMobile2 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile3 : json.Es.TextResponsabilitiesMobile3 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile4 : json.Es.TextResponsabilitiesMobile4 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile5 : json.Es.TextResponsabilitiesMobile5 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile6 : json.Es.TextResponsabilitiesMobile6 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile7 : json.Es.TextResponsabilitiesMobile7 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile8 : json.Es.TextResponsabilitiesMobile8 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile9 : json.Es.TextResponsabilitiesMobile9 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile10 : json.Es.TextResponsabilitiesMobile10 }<br />
                    </p>
                    <p class="subtitle-vacante">{ language ? json.En.TextRqusitosesMobile : json.Es.TextRqusitosesMobile }</p>
                    <p class="texto-vacant-descrip" style={{ lineHeight: "1.8em" }}>
                        - { language ? json.En.textTeam1 : json.Es.textTeam1 }.<br />

                        - { language ? json.En.textTeam2 : json.Es.textTeam2 }<br />

                        - { language ? json.En.textTeam3 : json.Es.textTeam3 }<br />

                        - { language ? json.En.textTeam4 : json.Es.textTeam4 }<br />

                        - { language ? json.En.textTeam5 : json.Es.textTeam5 }<br />

                        - { language ? json.En.textTeam6 : json.Es.textTeam6 }<br />

                        - { language ? json.En.textTeam7 : json.Es.textTeam7 }<br />
                    </p>
                </article>
                
            );
            break;
            case "mobile-native":
            contModal = (
                
                <article class="vacante-descrip mobile-native" >
                    {/* style={{ display: style3.display }} */}
                    <p class="title-vacant-des">
                        <img src="css/images/vacantes.png" class="icon-vacant" alt="walliavacante"/>{ language ? json.En.TextVacantMobile : json.Es.TextVacantMobile }</p>
                    <br />
                    <p class="texto-vacant-descrip">{ language ? json.En.TextVacantMobileDescription4 : json.Es.TextVacantMobileDescription4 }</p>

                    <p class="subtitle-vacante">{ language ? json.En.TextResponsabilitiesMobile : json.Es.TextResponsabilitiesMobile }</p>

                   
                    <p class="texto-vacant-descrip">
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile1 : json.Es.TextResponsabilitiesMobile1 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile2 : json.Es.TextResponsabilitiesMobile2 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile3 : json.Es.TextResponsabilitiesMobile3 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile4 : json.Es.TextResponsabilitiesMobile4 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile5 : json.Es.TextResponsabilitiesMobile5 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile6 : json.Es.TextResponsabilitiesMobile6 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile7 : json.Es.TextResponsabilitiesMobile7 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile8 : json.Es.TextResponsabilitiesMobile8 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile9 : json.Es.TextResponsabilitiesMobile9 }<br />
                        <span class="vacant"><b>·</b></span> { language ? json.En.TextResponsabilitiesMobile10 : json.Es.TextResponsabilitiesMobile10 }<br />
                    </p>
                    <p class="subtitle-vacante">{ language ? json.En.TextRqusitosesMobile : json.Es.TextRqusitosesMobile }</p>
                    <p class="texto-vacant-descrip" style={{ lineHeight: "1.8em" }}>
                        - { language ? json.En.textTeam1 : json.Es.textTeam1 }<br />

                        - { language ? json.En.textTeam2 : json.Es.textTeam2 }<br />

                        - { language ? json.En.textTeam3 : json.Es.textTeam3 }<br />

                        - { language ? json.En.textTeam4 : json.Es.textTeam4 }<br />

                        - { language ? json.En.textTeam5 : json.Es.textTeam5 }<br />

                        - { language ? json.En.textTeam6 : json.Es.textTeam6 }<br />

                        - { language ? json.En.textTeam7 : json.Es.textTeam7 }<br />
                    </p>
                </article>
            );
            break;
            case "modal":
            contModal = (
            <div class="modalSyle ">
                <section class="modal-wallia">
                    <div class="grupo-cerrar-ventana">
                     <img class="cerrar-ventana-icon close" src="css/images/cerrar-ventana.svg"  onClick={() => { setOnModal(false); }} style={{cursor:"pointer"}} alt="wallia_close"/>
                     </div>
                     <div class="grupo-mensaje">
                     <div class="mensaje-img">
                      <img src="css/images/exito.png" alt="" class="icon-message-wallia00"/>
                        </div>
                         <div class="mensaje-header">
                         <p className="waliitex">{ language ? json.En.TextoMensaje1 : json.Es.TextoMensaje1 }</p>
                         </div>
                                <div class="mensaje-texto">
                                <p >{ language ? json.En.TextoMensaje2 : json.Es.TextoMensaje2 }</p>
                             </div>
                            </div>
                            <div class="grupo-boton">
                        <button class="boton-continuar close" onClick={() => { setOnModal(false); }} style={{cursor:"pointer"}}>Continuar</button>
                        </div>
                   </section>
            </div>
            );
            
            break;
            
            case "modal1":
                contModal = (
                    
                    <section class="modal-wallia">
                        <div class="grupo-cerrar-ventana">
                         <img class="cerrar-ventana-icon close" src="css/images/cerrar-ventana.svg"  onClick={() => { setOnModal(false); }} style={{cursor:"pointer"}} alt="wallia_close"/>
                         </div>
                         <div class="grupo-mensaje">
                         <div class="mensaje-img">
                          <img src="css/images/error.png" alt="" class="icon-message-wallia00"/>
                            </div>
                             <div class="mensaje-header">
                             <p className="waliitex">{ language ? json.En.TextoMensaje1 : json.Es.TextoMensaje1 }</p>
                             </div>
                                    <div class="mensaje-texto">
                                    <p >{ language ? json.En.TextoMensaje2 : json.Es.TextoMensaje2 }</p>
                                 </div>
                                </div>
                                <div class="grupo-boton">
                            <button class="boton-continuar close" onClick={() => { setOnModal(false); }} style={{cursor:"pointer"}}>Continuar</button>
                            </div>
                       </section>
                   
                );
                
                break;
            default:
                break;
        }
    return (
        <>
        {contModal}
        </>
    );
}